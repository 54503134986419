import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import Form_Letter from '../../../../../../../common/src/assets/image/whatsNew/Form-Letter.png';
import template_options_form_letter from '../../../../../../../common/src/assets/image/template_options_form_letter.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Form Letter template generates a form letter using the data from
        scheduling software such as Google Calendar, Outlook, and VueMinder. The
        concept is similar to "mail merge", except instead of merging address
        book data into a form letter, it merges calendar data. An example
        template is provided and can be fully customized. The template uses
        placeholder tags which are replaced with event data when form letters
        are generated.
      </p>
      <p>
        <Image alt="" src={Form_Letter} />
      </p>
      <h2>Placeholders</h2>
      <p>
        The Form Letter template works by using "placeholder" tags. These tags
        will be replaced by data extracted from the imported events or tasks.
        The default form letter can be{' '}
        <Link to="/Documentation/How-To/Customize-a-Template">customized</Link>{' '}
        by clicking the Customize button on the PrintableCal tab, and the
        following placeholder tags can be used within the template as needed:
      </p>
      <ul>
        <li>
          <strong>&lt;&lt;Title&gt;&gt;</strong> - The event title or summary
          text
        </li>
        <li>
          <strong>&lt;&lt;Location&gt;&gt;</strong> - The event location.
        </li>
        <li>
          <strong>&lt;&lt;Calendar&gt;&gt;</strong> - The name of the calendar
          associated with the event.
        </li>
        <li>
          <strong>&lt;&lt;Start Date&gt;&gt; </strong>- The event starting date,
          using the system default format.
        </li>
        <li>
          <strong>&lt;&lt;Start Date Short&gt;&gt;</strong> - The event starting
          date, using a short format.
        </li>
        <li>
          <strong>&lt;&lt;Start Date Long&gt;&gt; </strong>- The event starting
          date, using a long format.
        </li>
        <li>
          <strong>&lt;&lt;End Date Short&gt;&gt; </strong>- The event ending
          date, using a short format.
        </li>
        <li>
          <strong>&lt;&lt;End Date&gt;&gt; </strong>- The event ending date,
          using the system default format.
        </li>
        <li>
          <strong>&lt;&lt;End Date Long&gt;&gt;</strong> - The event ending
          date, using a long format.
        </li>
        <li>
          <strong>&lt;&lt;Start Time&gt;&gt;</strong> - The event starting time.
        </li>
        <li>
          <strong>&lt;&lt;End Time&gt;&gt;</strong> - The event ending time.
        </li>
        <li>
          <strong>&lt;&lt;Start Date/Time&gt;&gt;</strong> - The event starting
          date and time, using the system default format.
        </li>
        <li>
          <strong>&lt;&lt;Start Date/Time Short&gt;&gt;</strong> - The event
          starting date and time, using a short format.
        </li>
        <li>
          <strong>&lt;&lt;Start Date/Time Long&gt;&gt;</strong> - The event
          ending date and time, using a long format.
        </li>
        <li>
          <strong>&lt;&lt;End Date/Time&gt;&gt;</strong> - The event ending date
          and time, using the system default format.
        </li>
        <li>
          <strong>&lt;&lt;End Date/Time Short&gt;&gt;</strong> - The event
          ending date and time, using a short format.
        </li>
        <li>
          <strong>&lt;&lt;End Date/Time Long&gt;&gt;</strong> - The event ending
          date and time, using a long format.
        </li>
        <li>
          <strong>&lt;&lt;Duration&gt;&gt;</strong> - The event duration.
        </li>
        <li>
          <strong>&lt;&lt;Description Line 1&gt;&gt;</strong> - The first line
          of the event description or details.
        </li>
        <li>
          <strong>&lt;&lt;Description Line 2&gt;&gt;</strong> - The second line
          of the event description or details.
        </li>
        <li>
          <strong>&lt;&lt;Description Line 3&gt;&gt;</strong> - The third line
          of the event description or details.&nbsp;
        </li>
        <li>
          <strong>&lt;&lt;Description Line X&gt;&gt;</strong> - You can define
          the template with up to 99 "Description Line X" placeholders, where X
          is a line number between 1 and 99. A single "line" is terminated by a
          carriage return and can be word-wrapped in the source event. A line
          can actually be an entire paragraph as long as it doesn't contain
          carriage returns and doesn't exceed 255 characters in length.
        </li>
      </ul>
      <h2>Template Settings</h2>
      <p>
        The Form Letter template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_form_letter} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Date range</strong>
            </td>
            <td>
              The start and end date range. Events or tasks within the specified
              date range will be used to populate form letters. Dates can be
              typed in or selected by clicking the drop-down arrow.
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Form-Letter"
      commentsId="commentsplus_post_184_595"
      title="Form Letter Template"
      description="The Form Letter template generates dynamic form letters based on data entered in imported events or tasks. This is similar to a Mail Merge feature, except it uses scheduling data rather than address book data."
      keywords="form letter, mail merge, events, calendar"
      content={content}
    />
  );
};

export default Documentation;
